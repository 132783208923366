import { lazy, Suspense } from "react"
import { RouteObject } from "react-router"

const RollHome = lazy(() => import("@/pkg_tool/roll/rollHome"))
const RollBox = lazy(() => import("@/pkg_tool/roll/rollBox"))
const RollNum = lazy(() => import("@/pkg_tool/roll/rollNum"))
const RollHelp = lazy(() => import("@/pkg_tool/roll/rollHelp"))

const actRouter: RouteObject[] = [
  {
    path: "/rollHome",
    element: <RollHome />,
  },
  {
    path: "/rollBox",
    element: (
      <Suspense fallback={<></>}>
        <RollBox />
      </Suspense>
    ),
  },
  {
    path: "/rollNum",
    element: (
      <Suspense fallback={<></>}>
        <RollNum />
      </Suspense>
    ),
  },
  {
    path: "/rollHelp",
    element: (
      <Suspense fallback={<></>}>
        <RollHelp />
      </Suspense>
    ),
  },
]

export default actRouter
