import { lazy } from "react"
import { RouteObject } from "react-router"

import actRouter from "./act" // 活动相关
import toolRouter from "./tool" // 工具相关
import bannerRouter from "./banner" // 活动图片展示相关

const User = lazy(() => import("@/pages/user"))
const AboutUs = lazy(() => import("@/pages/aboutUs"))
const Privacy = lazy(() => import("@/pages/privacy"))
const Agreement = lazy(() => import("@/pages/agreement"))
const HotDetails = lazy(() => import("@/pages/hotDetails"))
const BusinessDetails = lazy(() => import("@/pages/businessDetails"))
const Introduce = lazy(() => import("@/pages/introduce"))
const Association = lazy(() => import("@/pages/association"))
const ShopDetails = lazy(() => import("@/pages/shopDetails"))
const DowLoad = lazy(() => import("@/pages/dowload"))
const Guess = lazy(() => import("@/pages/guess"))
const RealName = lazy(() => import("@/pages/realName"))

const routers: RouteObject[] = [
  {
    path: "/",
    element: <div>这里是首页</div>,
  },
  // 关于我们
  {
    path: "/aboutUs",
    element: <AboutUs />,
  },
  // 隐私协议
  {
    path: "/privacy",
    element: <Privacy />,
  },
  // 使用协议
  {
    path: "/agreement",
    element: <Agreement />,
  },
  // 热点详情
  {
    path: "/hotDetails",
    element: <HotDetails />,
  },
  // 一分惊喜介绍
  {
    path: "/introduce",
    element: <Introduce />,
  },
  // 商品详情
  {
    path: "/shopDetails",
    element: <ShopDetails />,
  },
  // 商家详情
  {
    path: "/businessDetails",
    element: <BusinessDetails />,
  },
  // 实名认证
  {
    path: "/realName",
    element: <RealName />,
  },
  // 下载页
  {
    path: "/dowLoad",
    element: <DowLoad />,
  },
  {
    path: "/user", // 用户信息
    element: <User />,
    children: [
      {
        path: "tasks",
        element: <div>tasks</div>,
      },
    ],
  },
  // 社群
  {
    path: "/association",
    element: <Association />,
  },
  {
    path: "/guess",
    element: <Guess />,
  },
  ...actRouter,
  ...toolRouter,
  ...bannerRouter,
]

export default routers
