import { lazy } from "react"
import { RouteObject } from "react-router"

const Rate = lazy(() => import("@/pkg_banner/rate/index"))

const actRouter: RouteObject[] = [
  {
    path: "/rate",
    element: <Rate />,
  },
]

export default actRouter
