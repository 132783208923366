import React from "react"
import { useRoutes } from "react-router-dom"
import routers from "./router"

import cls from "./app.module.scss"

function App() {
  let element = useRoutes(routers)

  return (
    <div>
      <div className={cls.toast} style={{ display: "none" }} id="toast"></div>
      {element}
    </div>
  )
}

export default App
