import { lazy, Suspense } from "react"
import { RouteObject } from "react-router"

const Sign = lazy(() => import("@/pkg_act/sign"))
const Special = lazy(() => import("@/pkg_act/special"))
const TopPlayer = lazy(() => import("@/pkg_act/topPlayer"))
const Spirit = lazy(() => import("@/pkg_act/spirit"))
const FootBall = lazy(() => import("@/pkg_act/footBall"))
const Baoke = lazy(() => import("@/pkg_act/baoKe"))
const Free = lazy(() => import("@/pkg_act/free/freeAct"))
const FreeList = lazy(() => import("@/pkg_act/free/freeList"))
const DrawLots = lazy(() => import("@/pkg_act/drawLots"))
const Puzzle = lazy(() => import("@/pkg_act/puzzle"))

const actRouter: RouteObject[] = [
  {
    path: "/sign",
    element: <Sign />,
  },
  {
    path: "/special",
    element: <Special />,
  },
  {
    path: "/topPlayer",
    element: <TopPlayer />,
  },
  {
    path: "/spirits",
    element: <Spirit />,
  },
  {
    path: "/spirit",
    element: <Spirit />,
  },
  {
    path: "/footBall",
    element: <FootBall />,
  },
  {
    path: "/baoke",
    element: <Baoke />,
  },
  {
    path: "/free",
    element: <Free />,
  },
  {
    path: "/freeList",
    element: (
      <Suspense fallback={<></>}>
        <FreeList />
      </Suspense>
    ),
  },
  {
    path: "/drawLots",
    element: <DrawLots />,
  },
  {
    path: "/puzzle",
    element: <Puzzle />,
  },
]

export default actRouter
