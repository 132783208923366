import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import "@/static/js/rem";
import "./index.css";
import "@/static/css/iconfont.css";
import "@/static/css/transition.css";

window.toast = function (txt: string) {
  let dom = document.getElementById("toast");
  if (dom && dom.style.display === "none") {
    dom.style.display = "block";
    dom.innerHTML = txt;
    setTimeout(() => {
      dom && (dom.style.display = "none");
    }, 1500);
  }
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
